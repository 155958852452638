$(document).on('turbolinks:load', function(){
  $("#estudiantes").each(function(){
    $(this).DataTable({
      processing: true,
      serverSide: true,
      // responsive: {
      //         details: {
      //             type: 'column'
      //         }
      //     },
      "language": {
                 "lengthMenu": "Mostrar _MENU_ registros por página",
                 "zeroRecords": "Sin resultados",
                 "info": "_START_ a _END_ de _TOTAL_ registros",
                 "search": "Buscar",
                 "infoEmpty": "Sin registros disponibles",
                 "infoFiltered": "(filtrado de _MAX_ registros)",
                 "loadingRecords": "Loading...",
                 "processing":     "Procesando...",
                 "paginate": {
                   "first":      "First",
                   "last":       "Last",
                   "next":       "Sig",
                   "previous":   "Ant"
             }},
      ajax: {
        url: $('#estudiantes').data('source')
},
      columnDefs: [
       // {
       //     className: 'control',
       //     sortable: false,
       //     width: "10%",
       //     targets: 0
       // },
       { sortable: false,width: 5, targets: [ 0 ] },
       { sortable: true,width: 10, targets: [ 1 ] },
       { sortable: false, targets: [ 2 ] },
        { sortable: false, targets: [ '_all' ] }
      ]
    });
  });
})



$(document).on('turbolinks:load', function(){
  $("#periodos").each(function(){
    $(this).DataTable({
      processing: true,
      serverSide: true,
      // responsive: {
      //         details: {
      //             type: 'column'
      //         }
      //     },
      "language": {
                 "lengthMenu": "Mostrar _MENU_ registros por página",
                 "zeroRecords": "Sin resultados",
                 "info": "_START_ a _END_ de _TOTAL_ registros",
                 "search": "Buscar",
                 "infoEmpty": "Sin registros disponibles",
                 "infoFiltered": "(filtrado de _MAX_ registros)",
                 "loadingRecords": "Loading...",
                 "processing":     "Procesando...",
                 "paginate": {
                   "first":      "First",
                   "last":       "Last",
                   "next":       "Sig",
                   "previous":   "Ant"
             }},
      ajax: {
        url: $('#periodos').data('source')
},
      columnDefs: [
       // {
       //     className: 'control',
       //     sortable: false,
       //     width: "10%",
       //     targets: 0
       // },
       { sortable: true, targets: [ 0 ] },
       { sortable: true, targets: [ 1 ] },
        { sortable: false, targets: [ '_all' ] }
      ]
    });
  });
})
