$(function () {

  // Daterange picker
//  moment.locale('es');
  $('.input-daterange-datepicker').daterangepicker({
      locale: {
          format: 'DD/MM/YYYY'
        },
      buttonClasses: ['btn', 'btn-sm'],
      applyClass: 'btn-danger',
      cancelClass: 'btn-inverse'

  });
  moment.locale('es');
  $('.input-daterange-timepicker').daterangepicker({
      timePicker: true,
      locale: {
            format: 'DD/MM/YYYY HH:mm'
          },
      timePickerIncrement: 15,
      timePicker24Hour: true,
      timePickerSeconds: false,
      buttonClasses: ['btn', 'btn-sm'],
      applyClass: 'btn-danger',
      cancelClass: 'btn-inverse'
  });

  $('.input-limit-datepicker').daterangepicker({
      format: 'MM/DD/YYYY',
      minDate: '06/01/2015',
      maxDate: '06/30/2015',
      buttonClasses: ['btn', 'btn-sm'],
      applyClass: 'btn-danger',
      cancelClass: 'btn-inverse',
      dateLimit: {
          days: 6
      }
  });




  $('.datepicker-autoclose').datepicker({
          language: 'es',
          autoclose: true,
          todayHighlight: true,
          changeMonth: true,
          changeYear: true,
          format: 'dd/mm/yyyy'
      });

});
