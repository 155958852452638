jQuery(document).ready(function() {
        // For select 2
        $(".select2").select2();
  //      $('.selectpicker').selectpicker();

  $("#carreras_estudiante_persona_id").select2({
    dropdownParent: $("#modal_agregar_estudiante_cohorte")
  });



  });
