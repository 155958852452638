$(document).on("change", "#aula_nivel_id", function(){
  var nivel = $(this).val();

  $.ajax({
    url: "/aula_nivel_divisiones/nivel_division",
    method: "GET",
    dataType: "json",
    data: {nivel_id: nivel},
    error: function (xhr, status, error) {
      console.error('AJAX Error: ' + status + error);
    },
    success: function (response) {
      console.log(response);
      var divisiones = response["divisiones"];
//      if (provincia == '') {
//  $("#localidad_id").empty();
//} else {
  $("#aula_division_id").empty();
  $("#aula_division_id").append('<option>Selecciona una opción</option>');

      for(var i=0; i< divisiones.length; i++){

        $("#aula_division_id").append('<option value="' + divisiones[i]["id"] + '">' + divisiones[i]["nombre"] + '</option>');
      }
//    }

    }
  });
});
