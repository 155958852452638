$(document).on("change", "#persona_provincia_id", function(){
  var provincia = $(this).val();

  $.ajax({
    url: "/admin/prov_loc",
    method: "GET",
    dataType: "json",
    data: {provincia_id: provincia},
    error: function (xhr, status, error) {
      console.error('AJAX Error: ' + status + error);
    },
    success: function (response) {
      console.log(response);
      var localidades = response["localidades"];
//      if (provincia == '') {
//  $("#localidad_id").empty();
//} else {
  $("#persona_localidad_id").empty();
  $("#persona_localidad_id").append('<option>Selecciona una localidad</option>');

      for(var i=0; i< localidades.length; i++){

        $("#persona_localidad_id").append('<option value="' + localidades[i]["id"] + '">' + localidades[i]["nombre"] + '</option>');
      }
//    }

    }
  });
});
