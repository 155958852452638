// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("local-time").start()
global.toastr = require("toastr")
global.moment = require('moment');

//import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
//import ClassicEditor from '@ckeditor/ckeditor5-editor-classic';
//import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';
//import DecoupledEditor from '@ckeditor/ckeditor5-editor-decoupled';

//import Font from '@ckeditor/ckeditor5-font/src/font';
//require("trix")
//require("@rails/actiontext")
//import 'ckeditor4'
//require("ckeditor5-build-full-plugins")

import 'bootstrap';
//import "@fortawesome/fontawesome-free/css/all"
//import Swal from 'sweetalert2'
//window.Swal = Swal;
////////////// AdminPro ////////////////////
require("packs/adminPro/js/perfect-scrollbar.jquery.min");
require("packs/adminPro/js/waves");
require("packs/adminPro/js/sidebarmenu");


require("packs/adminPro/js/jquery.sticky");
require("packs/adminPro/js/custom.min");
require("packs/adminPro/js/select2.full.min");
require("packs/adminPro/js/bootstrap-material-datetimepicker");
require("packs/adminPro/js/daterangepicker");
require("packs/adminPro/js/bootstrap-datepicker.min");
require("packs/adminPro/js/bootstrap-datepicker.es");
//require("packs/adminPro/js/sweetalert");
require("packs/adminPro/js/bootstrap-timepicker.min");
//import 'dropify';
require("packs/customJS/data-confirm");
require("packs/customJS/ckeditor");
require("packs/customJS/ckeditor_custom");
require("packs/customJS/addFields");
require("packs/customJS/removeFields");
//require("packs/adminPro/js/jquery.magnific-popup");
//require("packs/adminPro/js/jquery.magnific-popup-init");
//require("packs/customJS/ckeditor_1");
require("packs/customJS/direct_uploads");
require("packs/customJS/provincias_localidades_select");
require("packs/customJS/form_aula");
require("packs/customJS/datepicker");
require("packs/customJS/select2");


///////////// Ends AdminPro ////////////////
// Custom Datatables definitions
//////////// DataTable ////////////////////
import 'datatables.net';
import 'datatables.net-bs4';
// Custom Datatables definitions
require("packs/customJS/custom_datatables");

// fecha por rango date and datetime
require("packs/customJS/daterange.js");
import  "packs/stylesheets/application"
/// jquery
import $ from 'jquery';
global.$ = jQuery;
window.jQuery = $;
import Rails from '@rails/ujs';
global.Rails = Rails;
